import {
  IndividualPlaybookModuleNames,
  RelationshipPlaybookModuleNames,
  GroupPlaybookModuleNames,
  PlaybookModuleTypes,
  JobPlaybookModuleNames,
  JobProfilePlaybookModuleNames,
  JobProfilesPlaybookModuleNames,
  JobsProfilePlaybookModuleNames,
} from './playbooks'

interface SalesforceApiResponse {
  id: string
  token: string
}

export interface DropdownOption {
  label: string
  value: string | null
  grey?: boolean
  spacer?: boolean
}

export type LoadingStates = 'default' | 'loading' | 'success' | 'failure'

export type StepNames =
  | 'connect'
  | 'job'
  | 'chrome'
  | 'information'
  | 'shortAssessment'
  | 'choosePath'

export type TableModes =
  | 'members'
  | 'subscriptions'
  | 'settings'
  | 'integrations'
  | 'profile-content'
  | 'custom-content'

export type APIResponse<T> = { data: T } & Record<string, unknown>

export interface APIPagedResponse<T> {
  data: T[]
  total_count: number
  total_pages: number
}

export interface PagedOptions {
  page?: number
  per_page?: number
}

export type KeyedStateHelper<T> = Record<string, T | undefined>

export type AccessTiers = 'hiring_tier' | 'sales_tier' | 'leadership_tier'

interface Access {
  team: TeamAccess
  hiring_tier: PlanTiers | null
  sales_tier: PlanTiers | null
  leadership_tier: PlanTiers | null
  referral: {
    access: boolean
  }
}

interface AuthUserEmail {
  email: string
  primary: boolean
  confirmed_at: string
}

interface CompanyDomain {
  company_id: string
  created_at: string
  domain: string
  id: number
  source: string
  updated_at: string
}

export type Products = 'leadership' | 'sales'

export type PlanTiers = 1 | 2 | 3

const zeroToTwentySix = [
  0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
  22, 23, 24, 25, 26,
] as const
const zeroToFive = [0, 1, 2, 3] as const

const salesPlanTierMonthlyNames = zeroToTwentySix.map(tier => {
  return `sales-tier-${tier}-monthly`
})
const salesPlanTierYearlyNames = zeroToTwentySix.map(tier => {
  return `sales-tier-${tier}-yearly`
})

const leadershipPlanTierMonthlyNames = zeroToFive.map(tier => {
  return `leadership-tier-${tier}-monthly`
})
const leadershipPlanTierYearlyNames = zeroToFive.map(tier => {
  return `leadership-tier-${tier}-yearly`
})

const hiringPlanTierMonthlyNames = zeroToFive.map(tier => {
  return `hiring-tier-${tier}-monthly`
})
const hiringPlanTierYearlyNames = zeroToFive.map(tier => {
  return `hiring-tier-${tier}-yearly`
})

// prettier-ignore
type SalesPlanTypes =
  | 'free'
  | (typeof salesPlanTierMonthlyNames)[number]
  | (typeof salesPlanTierYearlyNames)[number]
  | 'custom'

// prettier-ignore
type LeadershipPlanTypes =
  | 'free'
  | (typeof leadershipPlanTierMonthlyNames)[number]
  | (typeof leadershipPlanTierYearlyNames)[number]
  | 'custom'

// prettier-ignore
type HiringPlanTypes =
  | 'free'
  | (typeof hiringPlanTierMonthlyNames)[number]
  | (typeof hiringPlanTierYearlyNames)[number]
  | 'custom'

export type PlanTypes = SalesPlanTypes | LeadershipPlanTypes | HiringPlanTypes

export type DiscType =
  | 'Dc'
  | 'D'
  | 'Di'
  | 'DI'
  | 'Id'
  | 'I'
  | 'Is'
  | 'IS'
  | 'Si'
  | 'S'
  | 'Sc'
  | 'SC'
  | 'Cs'
  | 'C'
  | 'Cd'
  | 'CD'

export type ParamDiscType =
  | 'd'
  | 'di'
  | 'd_i'
  | 'id'
  | 'i'
  | 'is'
  | 'i_s'
  | 'si'
  | 's'
  | 'sc'
  | 's_c'
  | 'cs'
  | 'c'
  | 'cd'
  | 'c_d'
  | 'dc'

export type ParamDiscList = {
  param: ParamDiscType
  type: DiscType
  degrees: number
}
export interface SubscriptionStates {
  state: LoadingStates
  err?: string
}

type ReportTypes =
  | 'group'
  | 'relationship'
  | 'chemistry'
  | 'group_fit'
  | 'individual'

interface AuthUserEmail {
  email: string
  primary: boolean
}

export type AuthUserPrivacy = 'global' | 'private' | 'teammate'

export type Usage = {
  usage: number
  allowance: number
}

export type CreditLedger = {
  total: number
  used: number
  balance: number
  lastBillingDate: string
}

type UsageWithId = Usage & { id: string }

export interface FullAuthUser {
  access: Access
  actions: Record<string, string | undefined>
  company_domains: CompanyDomain[] | null
  company_id: string | null
  company_name: string | null
  created_at: string | null
  email: string
  emails: Array<AuthUserEmail>
  first_name: string
  friendly_name?: string | null
  id: string
  job_title: string
  last_name: string
  profile: AuthUserProfile
  profile_id: string
  role?: string
  team: AuthUserTeam
  api?: {
    id: string
    token: string
    name: string
  }
  chrome_extension_views: Usage
  current_segment?: SegmentTypes
  features: UserFeatures
  team_invites: InviteObject[]
  side_navigation: boolean
  impersonation?: boolean
  prediction_tally?: UsageWithId
  verified_email?: boolean
}

export interface InviteObject {
  team_id: string
  team_name: string
  inviter_name: string
  inviter_photo_url: string | null
  properties: null | InviteTeamId
}

interface InviteTeamId {
  team_unit_id: string
}

export type SegmentTypes = 'leadership' | 'sales'

interface PlanChangeObject {
  plan_id: PlanTypes
  tier: number
  quantity: number
}

export interface ProductObject {
  id: string
  plan_id: PlanTypes | null
  subscription_ends_at: string
  discount: string
  remaining: null | number
  tier: PlanTiers
  deprecated: string
  interval: 'monthly' | 'yearly'
  billing_cycle_at: string
  custom: boolean
  recent_change: PlanChangeObject | null
  pause: { start_at: string } | null
  type: Products
  auto_assign: boolean
  quantity?: number
  cost: number | null
}

export interface CustomerSuccessManager {
  id: string
  first_name: string
  last_name: string
  email: string
  photo_url: string | null
}

export interface OrgPredictionTallyObject {
  id: string
  usage: number
  allowance: number
}

export interface AuthUserTeam {
  id: string
  name: string
  logo_url?: string
  leadership?: ProductObject
  sales?: ProductObject
  hiring?: ProductObject
  seat_tally: UsageWithId
  leadership_seat_tally: UsageWithId
  sales_seat_tally: UsageWithId
  candidates_tally?: UsageWithId
  billing_notification?: string
  features: UserFeatures
  terms_of_service?: string
  customer_success_manager?: CustomerSuccessManager
  org_predictions_tally?: OrgPredictionTallyObject
  enterprise_org?: string
  hubspot_organization_id?: string
  layout_settings: TeamLayoutSettings
  use_email_branding: boolean
  use_product_branding: boolean
  platform_seat_limit?: number
}

export interface Team {
  id: string
  name: string
  type: string
  logo_url: string | null
  leadership: ProductObject | null
  sales: ProductObject | null
  hiring: ProductObject | null
  playbook_tally: UsageWithId
  seat_tally: UsageWithId
  leadership_seat_tally: UsageWithId
  sales_seat_tally: UsageWithId
  candidates_tally: UsageWithId | null
  billing_notification: string | null
  billing_emails: string[] | null
  show_celebrities: boolean
  enable_global_profile_enrichment: boolean
  customer_id: string | null
  slack: boolean
  api: SalesforceApiResponse | null
  features: UserFeatures
  terms_of_service: string | null
  customer_success_manager: null | CustomerSuccessManager
  org_predictions_tally: OrgPredictionTallyObject | null
  enterprise_org: string | null
  hubspot_organization_id: string | null
  layout_settings: TeamLayoutSettings
  use_email_branding: boolean
  use_product_branding: boolean
  platform_seat_limit: number | null
}

export interface TeamSimple {
  id: string
  name: string
  count: number
  members: SimpleInviteProfile[]
  licenses: {
    sales: boolean
    leadership: boolean
    hiring: boolean
  }
  inviter_info?: {
    name: string
    photo_url: string | null
  }
}

export type TeamLayoutSettings = {
  [key in ProfileModuleName]: boolean
}
interface TeamAccess {
  member_manager: boolean
  hiring_product: boolean
}

interface TeamInvite {
  accepted: boolean
  rejected: boolean
  invitee_email: string
  responded_at: string
}

export type InviteResponse = APIPagedResponse<MemberInvite>

export interface TeamStats {
  usage: {
    total: {
      predictions: number
      playbooks: number
      profile_views: number
    }
    profiles: APIPagedResponse<TeamProfileData>
  }
}

export interface TeamProfileData {
  profile: TeamUser
  predictions: number
  prediction_tally: UsageWithId | null
  playbooks: number
  profile_views: number
}

export interface SimpleInviteProfile {
  id: string
  first_name: string
  last_name: string
  photo_url: string
  job_title: string
}

export type TeamUser = {
  access: Access
  company_name: string | null
  email: string
  first_name: string
  id: string
  invite_token: string
  job_title: string | null
  last_login_at: string | null
  last_active_at: string | null
  last_name: string
  personality: Personality
  photo_url: string
  team_invite: TeamInvite
  team_role: string
  pending: boolean
  job_access?: {
    viewer?: boolean
  }
  tags: Tag[]
  prediction_allowance: number
  prediction_usage: number
}

export type TeamUserMinimal = Pick<
  TeamUser,
  'id' | 'first_name' | 'last_name' | 'photo_url' | 'job_access' | 'job_title'
>

export type Teams = Array<Team>

interface CircumplexVector {
  degrees: number
  intensity: number
}

export interface DiscDataCircumplexVector {
  degrees: number
  intensity: number
  name?: string
  gender?: string
}

export interface Personality {
  archetype?: string
  degrees: number
  disc_type: DiscType
  intensity: number
  updated_by?: boolean
}

export interface PublicProfile {
  first_name: string
  last_name: string
  photo_url?: string | null
  company_name?: string | null
}

export interface DiscPercentages {
  d: number
  i: number
  s: number
  c: number
}

export interface ProfilePersonality extends Personality {
  four_percentages: DiscPercentages
  created_at?: string
  updated_at?: string
  confidence_score?: number
  frequency_table?: {
    D: number
    I: number
    S: number
    C: number
  }
}

export interface AuthUserProfile {
  company_name?: string
  current_associations: string[]
  first_name: string
  id: string
  job_title?: string
  last_name: string
  personality?: ProfilePersonality
  photo_url?: string
  type: string
  vanity_url?: string
  tags?: Tag[]
  verified: boolean
}

export interface Profile {
  company_name: string | null
  current_associations: string[]
  first_name: string
  friendly_id?: string | null
  gender: string | null
  id: string
  job_title: string | null
  last_name: string
  myers_briggs_text_type: null | string
  paywall_code?: number
  personality: null | ProfilePersonality
  job_behavior?: null | ProfilePersonality
  photo_url?: string | null
  profile_api_uuid?: string
  profile_root?: string | null
  strengths: Record<string, string>
  show_paywall?: boolean
  sources: string[]
  type: string
  vanity_url: null | string
  qualities?: string[]
  tags?: Tag[]
  accuracies: {
    disc: number
  } | null
  privacy: 'private' | 'global' | 'team_only'
  notes: null | Record<string, string>
  accessible?: boolean
  job_candidate?: boolean
  linkedin_url?: string
  verified: boolean
  editable: boolean
  correlation_invite_token: string | null
  product_access: null | {
    hiring_tier: null | number
    leadership_tier: null | number
    sales_tier: null | number
  }
  job_fit: number | null
  last_active_at?: Date
  assessment_taken?: boolean
  status: ProfileStatus | null
}

interface ProfileStatus {
  display_text: string
  key: 'user' | 'predicted' | 'edited'
}

export interface CandidateProfile extends Profile {
  job_fit: number | null
  active: boolean
  added_at: string
}

export interface AssessmentQuestion {
  id: number
  options: { id: string; text: string }[]
}

export interface AssessmentResponse {
  question_id: number
  option_id: string
  most_or_least: 'most' | 'least'
}

export type AssessmentResponses = AssessmentResponse[]

export interface DropdownItem {
  label: string
  value: string
}

interface Endorsement {
  disc_type: DiscType
  first_name: string
  last_name: string
  photo_url: string
  profile_id: string
}

export type ProfileOverview = {
  overview: string
}

export type ProfileContent = Record<string, Datum[] | undefined>

export interface Datum {
  id: string
  signature: string
  text: string
  degrees: number
  intensity: number
  tags: string[]
  disc_data_id: string
  endorsed_by_me?: boolean
  endorsements?: Array<Endorsement>
}

export type AssessmentTypes = 'disc'

const DO_DONT_MODULE_NAMES = [
  'individual_connect_on_linkedin_do_dont',
  'individual_discuss_pricing_do_dont',
  'individual_follow_up_do_dont',
  'individual_gather_information_do_dont',
  'individual_give_a_demo_do_dont',
  'individual_introduce_someone_do_dont',
  'individual_good_impression_do_dont',
  'individual_sales_pitch_do_dont',
  'individual_negotiate_do_dont',
  'individual_persuade_to_take_action_do_dont',
  'individual_say_no_do_dont',
  'individual_say_thank_you_do_dont',
  'individual_schedule_a_meeting_do_dont',
  'individual_set_expectations_do_dont',
] as const

const MULTIPLE_SECTIONS_MODULE_NAMES = [
  'individual_sales_pitch_multiple_sections',
  'individual_good_impression_multiple_sections',
  'individual_schedule_a_meeting_multiple_sections',
  'individual_give_a_demo_multiple_sections',
  'individual_pain_points_multiple_sections',
  'individual_urgency_and_pace_multiple_sections',
  'individual_handling_competition_multiple_sections',
  'individual_problems_approach_multiple_sections',
  'adaptive_messaging',
] as const

const TWO_COLUMNS_MODULE_NAMES = [
  'working_with_a_boss',
  'working_with_a_boss_job_behavior',
  'working_with_peers',
  'working_with_peers_job_behavior',
  'working_with_direct_reports',
  'working_with_direct_reports_job_behavior',
  'ideal_job_responsibilities',
  'ideal_job_responsibilities_job_behavior',
] as const

const INDIVIDUAL_PHRASES_MODULE_NAMES = [
  'individual_responding_to_stress',
  'individual_potential_careers',
] as const

const INDIVIDUAL_SELF_IMPROVEMENT_MODULE_NAMES = [
  'individual_be_more_productive',
  'individual_be_more_productive_job_behavior',
  'individual_communicate_more_effectively',
  'individual_communicate_more_effectively_job_behavior',
  'individual_negotiate_more_effectively',
  'individual_resolve_conflicts',
  'individual_lead_more_effectively',
] as const

export const INDIVIDUAL_CONTENT_MODULE_NAMES = [
  ...DO_DONT_MODULE_NAMES,
  ...MULTIPLE_SECTIONS_MODULE_NAMES,
  ...TWO_COLUMNS_MODULE_NAMES,
  ...INDIVIDUAL_PHRASES_MODULE_NAMES,
  ...INDIVIDUAL_SELF_IMPROVEMENT_MODULE_NAMES,
]

export type DoDontModulesNames = (typeof DO_DONT_MODULE_NAMES)[number]
export type MultipleSectionsModuleNames =
  (typeof MULTIPLE_SECTIONS_MODULE_NAMES)[number]
export type TwoColumnsModuleNames = (typeof TWO_COLUMNS_MODULE_NAMES)[number]
export type IndividualPhrasesModuleNames =
  (typeof INDIVIDUAL_PHRASES_MODULE_NAMES)[number]
export type IndividualSelfImprovementModuleNames =
  (typeof INDIVIDUAL_SELF_IMPROVEMENT_MODULE_NAMES)[number]
export type IndividualContentModuleNames =
  (typeof INDIVIDUAL_CONTENT_MODULE_NAMES)[number]

export type ContentModuleNames = IndividualContentModuleNames

export function printFiltersForIndividualModules(
  moduleName: IndividualContentModuleNames,
) {
  switch (moduleName) {
    case 'individual_connect_on_linkedin_do_dont':
      return 'Connect on LinkedIn'
    case 'individual_discuss_pricing_do_dont':
      return 'Discuss pricing'
    case 'individual_follow_up_do_dont':
      return 'Follow up'
    case 'individual_gather_information_do_dont':
      return 'Gather information'
    case 'individual_give_a_demo_do_dont':
      return 'Give a demo advice'
    case 'individual_introduce_someone_do_dont':
      return 'Introduce someone'
    case 'individual_good_impression_do_dont':
      return 'Good impresssion'
    case 'individual_sales_pitch_do_dont':
      return 'Sales pitch'
    case 'individual_negotiate_do_dont':
      return 'Negotiation'
    case 'individual_persuade_to_take_action_do_dont':
      return 'Persuade to take action'
    case 'individual_say_no_do_dont':
      return 'Say no'
    case 'individual_say_thank_you_do_dont':
      return 'Say thank you'
    case 'individual_schedule_a_meeting_do_dont':
      return 'Schedule a meeting'
    case 'individual_set_expectations_do_dont':
      return 'Set expectations'
    case 'individual_sales_pitch_multiple_sections':
      return 'Sales pitch'
    case 'individual_good_impression_multiple_sections':
      return 'Good impression'
    case 'individual_schedule_a_meeting_multiple_sections':
      return 'Schedule a meeting'
    case 'individual_give_a_demo_multiple_sections':
      return 'Give a demo'
    case 'individual_pain_points_multiple_sections':
      return 'Pain points'
    case 'individual_urgency_and_pace_multiple_sections':
      return 'Urgency and pace'
    case 'individual_handling_competition_multiple_sections':
      return 'Handling competition'
    case 'individual_problems_approach_multiple_sections':
      return 'Problems approach'
    case 'working_with_a_boss':
    case 'working_with_a_boss_job_behavior':
      return 'Working with a boss'
    case 'working_with_peers':
    case 'working_with_peers_job_behavior':
      return 'Working with peers'
    case 'working_with_direct_reports':
    case 'working_with_direct_reports_job_behavior':
      return 'Working with direct reports'
    case 'ideal_job_responsibilities':
    case 'ideal_job_responsibilities_job_behavior':
      return 'Ideal job responsibilities'
    case 'individual_responding_to_stress':
      return 'Responding To Stress'
    case 'individual_potential_careers':
      return 'Potential Careers'
    case 'individual_be_more_productive':
    case 'individual_be_more_productive_job_behavior':
      return 'Be More Productive'
    case 'individual_communicate_more_effectively':
    case 'individual_communicate_more_effectively_job_behavior':
      return 'Communicate More Effectively'
    case 'individual_negotiate_more_effectively':
      return 'Negotiate More Effectively'
    case 'individual_resolve_conflicts':
      return 'Resolve Conflicts'
    case 'individual_lead_more_effectively':
      return 'Lead More Effectively'
    case 'adaptive_messaging':
      return 'Adaptive Messaging'
    default:
      tsErrorHelper(moduleName)
      return ''
  }
}

export function tsErrorHelper(value: never) {
  value
}

export interface CircumplexBreakdownValue {
  archetype: string
  disc_type: DiscType
  overview: string
  strengths: string[]
}

export interface PrivacyOption {
  value: string
  label: string
  tooltip: string
}

export interface PlaybookHeaderOptions {
  hide_profile_photos?: 'true' | 'false'
  hide_title?: 'true' | 'false'
}

export interface PlaybookNote {
  id: string
  playbook_id: string
  section_id: string
  text: string
}

type PlaybookNotes = PlaybookNote[]

interface Playbook {
  id: string
  name: string | null
  title: string
  abbreviated_title: string
  template_name: string
  template_icon_url: string
  privacy: AuthUserPrivacy
  created_at: string
  created_by: string
  personality: Personality
  profiles: Profile[]
  jobs: JobResponse[]
  header_url: string | null
  header_options: PlaybookHeaderOptions
  custom_template: boolean
  notes: PlaybookNotes
  job_interview_assessment: ScoreAverageObject
  template_tags: PlaybookTemplateTag[]
}

export type PlaybookTemplateTag =
  | 'meeting'
  | 'interview'
  | 'leadership'
  | 'sales'
  | 'hiring'

export type TemplateSection<ModuleName> = {
  id: number
  name: ModuleName
}

export interface IndividualPlaybook extends Playbook {
  template_type: 'individual'
  sections: TemplateSection<IndividualPlaybookModuleNames>[]
}

export interface RelationshipPlaybook extends Playbook {
  template_type: 'relationship'
  sections: TemplateSection<RelationshipPlaybookModuleNames>[]
}

export interface GroupPlaybook extends Playbook {
  template_type: 'group'
  sections: TemplateSection<GroupPlaybookModuleNames>[]
}

export interface JobPlaybook extends Playbook {
  template_type: 'job'
  sections: TemplateSection<JobPlaybookModuleNames>[]
}
export interface JobProfilePlaybook extends Playbook {
  template_type: 'job_profile'
  sections: TemplateSection<JobProfilePlaybookModuleNames>[]
}
export interface JobProfilesPlaybook extends Playbook {
  template_type: 'job_profiles'
  sections: TemplateSection<JobProfilesPlaybookModuleNames>[]
}
export interface JobsProfilePlaybook extends Playbook {
  template_type: 'jobs_profile'
  sections: TemplateSection<JobsProfilePlaybookModuleNames>[]
}

export type PlaybookResponse =
  | IndividualPlaybook
  | RelationshipPlaybook
  | GroupPlaybook
  | JobPlaybook
  | JobProfilePlaybook
  | JobProfilesPlaybook
  | JobsProfilePlaybook

export interface Tag {
  id: string
  name: string
  personality: Personality | null
}

export type PlaybookTemplateType =
  | 'individual'
  | 'relationship'
  | 'group'
  | 'job'
  | 'job_profile'
  | 'job_profiles'
  | 'jobs_profile'

export interface PlaybookTemplateObject {
  custom: boolean
  id: string
  type: PlaybookTemplateType
  name: string
  icon_url: string
  sections: TemplateSection<PlaybookModuleTypes>[]
  permalink: string
}

interface ReportResponse {
  id: string
  name: string | null
  type: ReportTypes
  data:
    | Profile
    | {
        profiles: Profile[]
      }
  content: string | null
  created_at: string
}

export type PlaybookOrReportResponse = PlaybookResponse | ReportResponse

export interface SuperlativeObject {
  key: string
  profiles: Profile[]
  text: string
  total_count: number
  total_pages: number
}

export interface SuperlativeResponse {
  page_per_trait: Record<string, number>
  profiles_by_trait: SuperlativeObject[]
}

export interface JobSurveyTaker {
  first_name: string
  job_title: string
  last_name: string
  uuid: string
}

export interface SurveyProfile {
  id: string
  personality: { degrees: number; intensity: number }
  created_by: Profile | JobSurveyTaker
  exclude?: boolean
}

export interface DerivedProfile {
  id: string
  first_name: string
  last_name: string
  gender: string | null
  photo_url: string | null
  job_title: string | null
  personality: ProfilePersonality
}

export interface JobResponse {
  id: string
  name: string
  company_name: string
  description: string
  personality: null | {
    id: number
    resource_id: string
    disc_type_analysis: DiscType
    degrees: number
    intensity: number
    created_at: string
    updated_at: string
    resource_type: string
    updated_by_id: string | null
    four_percentages: DiscPercentages
  }
  description_personality: Personality | null
  created_by: Profile
  created_at: string
  privacy: AuthUserPrivacy
  total_profiles: number
  resource_id: string
  profiles: Profile[]
  active: boolean
  derived_profiles: DerivedProfile[]
}

export interface LegacyCreateCandidateResponse {
  id: string
}

export interface HiringBehavior {
  text: string
  interview_question: string
  score: number
  circumplex: CircumplexVector
}

export type CrystalChecklistMode = 'sales' | 'hiring' | 'coaching'

export interface PlaybookNote {
  id: string
  resource_id: string
  playbook_id: string
  section_id: string
  text: string
}

export interface ScoreAverageObject {
  id: string
  average_score: number
  answers: { text: string; score: number }[]
  created_by: AuthUserProfile | Profile
}

interface UserFeatures {
  white_label_playbook_banner?: boolean
  white_label_candidate_assessment?: boolean
  export_people_page?: boolean
  organization_groups?: boolean
  custom_terms_of_service?: boolean
  simplified_chinese_assessment?: boolean
  profile_based_tier_pricing?: boolean
  crystal_for_outlook_vsto?: boolean
  disable_job_fit_percentage?: boolean
  disable_calendar_integration?: boolean
}

export type SkillAssessmentCategory =
  | 'working_together'
  | 'communication'
  | 'resolving_conflict'

export interface APIErrorResponse {
  message: string
  parameter: string
  status: number
}

export interface MemberInvite {
  email: string
  team_id: string
  invite_token: string
  creator: Profile
  created_at: string
  updated_at: string
  resent: boolean
  job_title: string
}

export interface EmailResponse {
  id: string
  kind: 'team_invite'
  subject: string
  body: string
  logo_url: string | null
}

export interface CustomContentProfileResponse {
  section_name: string
  disc_content: CustomContentProfileDiscContent | null
}

interface CustomContentProfileDiscContent {
  content: string
  created_at: string
  disc_type: string
  id: number
  team_id: string
  updated_at: string
}

export interface CustomContentResponse {
  section_name: string
  disc_content: CustomDiscContent
}

export interface CustomDiscContent {
  Dc?: string
  D?: string
  Di?: string
  DI?: string
  Id?: string
  I?: string
  Is?: string
  IS?: string
  Si?: string
  S?: string
  Sc?: string
  SC?: string
  Cs?: string
  C?: string
  Cd?: string
  CD?: string
}

export type PlaybookTemplateProductType = 'leadership' | 'sales'

export interface GoogleCredentialType {
  /** The JWT's issuer */
  iss: string
  nbf: number
  /** Your server's client ID */
  aud: string
  /** The unique ID of the user's Google Account */
  sub: string
  /** If present, the host domain of the user's GSuite email address */
  hd: string
  /** The user's email address */
  email: string
  /** true, if Google has verified the email address */
  email_verified: boolean
  azp: string
  name: string
  /** If present, a URL to user's profile picture */
  picture: string
  given_name: string
  family_name: string
  /** Unix timestamp of the assertion's creation time */
  iat: number
  /** Unix timestamp of the assertion's expiration time */
  exp: number
  jti: string
}

export type SupportedColor =
  | DiscType
  | 'white'
  | 'black'
  | 'grey'
  | 'red'
  | 'midnight'
  | 'action-blue'
  | 'empty'
  | 'light-blue'
  | 'blue'
  | 'enneagram-'
  | 'enneagram-1'
  | 'enneagram-2'
  | 'enneagram-3'
  | 'enneagram-4'
  | 'enneagram-5'
  | 'enneagram-6'
  | 'enneagram-7'
  | 'enneagram-8'
  | 'enneagram-9'

export type JwtPayload = {
  exp: number
  uuid: string
  profile_id: string
  org_id?: string
  type: string
  friendly_name: string
  first_name?: string
  last_name?: string
}

export type AuthDetails = {
  authed?: boolean
  authToken: string | null
  sessionToken?: string | null
  appName?: string
  jwtPayload?: JwtPayload
}

export const USER_ACTIONS = {
  custom_terms: 'accepted_team_terms_of_service',
  completed_hiring_onboarding: 'completed_hiring_onboarding',
  completed_sales_onboarding: 'completed_sales_onboarding',
  closed_product_survey_recruitment: 'closed_product_survey_recruitment',
  completed_email_editor_onboarding: 'completed_email_editor_onboarding',
  completed_job_survey_answer_editing_onboarding:
    'completed_job_survey_answer_editing_onboarding',
  completed_dashboard_search_tutorial: 'completed_dashboard_search_tutorial',
  dismiss_onboarding_checklist_module: 'dismiss_onboarding_checklist_module',
  installed_chrome_extension: 'installed_chrome_extension',
  hide_sample_customer_profiles: 'hide_sample_customer_profiles',
  feedback_jobs_2020: 'feedback_jobs_2020',
  feedback_job_fit_2020: 'feedback_job_fit_2020',
  onboarding_started: 'onboarding_started',
  onboarding_complete: 'onboarding_complete',
} as const

export type TaskName =
  | 'notify_inviter'
  | 'accept_referral'
  | 'add_slack_team'
  | 'viewed_profile'
  | 'team_invite'
  | 'reject_team_invite'
  | 'correlate'
  | 'generic_invite'

export interface TaskOptions {
  profile_id?: string
  invite_token?: string
  slack_team_id?: string
  team_id?: string
  team_unit_id?: string
  candidate_id?: string
}

export const freeProfileSections = () => {
  return [
    'Personality overview',
    'At home disc',
    'Personality map',
    'Profile Proficiency',
  ] as const
}

export const lockedProfileSections = () => {
  return [
    'What comes naturally to first_name',
    'What energizes first_name',
    'What drains first_name',
    'Strengths for first_name',
    'Blind spots for first_name',
    'How to communicate with first_name',
    'How to email first_name',
    'Working with first_name',
    'What others say about first_name',
    'Personality comparison chart',
    'My Job Fit',
    'Job behavior disc',
  ] as const
}

export const predictedProfileSections = () => {
  return [
    'Selling to first_name',
    'Sales pitch to first_name',
    'Demo product for first_name',
    'Schedule a meeting with first_name',
    'How to call first_name',
    'How to email first_name',
    'How to negotiate with first_name',
    'How to discuss pricing with first_name',
    'How to follow up with first_name',
    'How to say no to first_name',
  ] as const
}

export const premiumSalesSections = () => {
  return [
    'first_name can give a product demo more effectively by...',
    'first_name can more effectively discuss pricing by...',
    'first_name can gather information more effectively by...',
    'first_name can help drive decisions by...',
  ] as const
}

enum ProfileSection {
  CustomContent = 'custom_content',
  DiscAtHome = 'disc_at_home',
  Traits = 'traits',
  V2Phrases = 'v2_phrases',
  Behavior = 'behavior',
  Energizers = 'energizers',
  Drainers = 'drainers',
  Strengths = 'strengths',
  BlindSpots = 'blindspots',
  FirstImpression = 'first_impression',
  BuildingTrust = 'building_trust',
  ProductDemo = 'product_demo',
  Negotiating = 'negotiating',
  Pricing = 'pricing',
  DrivingAction = 'driving_action',
  FollowingUp = 'following_up',
  QuickTipsMeetings = 'quick_tips_meetings',
  WritingStyle = 'writing_style',
  WritingAssistant = 'writing_assistant',
  WritingExamples = 'writing_examples',
  TeamRoles = 'team_roles',
  Playbooks = 'playbooks',
  Disc = 'disc',
}

export type ProfileModuleName = `${ProfileSection}`

const allFilters = [
  ...freeProfileSections(),
  ...lockedProfileSections(),
  ...predictedProfileSections(),
  ...premiumSalesSections(),
  ...INDIVIDUAL_CONTENT_MODULE_NAMES.map(printFiltersForIndividualModules),
]
export type ProfilePrintFilterKeys = (typeof allFilters)[number]

export type AddProfilesModalView = 'home' | 'assessment' | 'linkedin' | 'email'

export type ChipTextAreaTypes = 'linkedin' | 'email'

export type ProfilePageName = 'candidate' | 'coworker' | 'library'
