interface OpenWindowOptions {
  path: string
  windowName: string // Should be single spaced to support IE
  windowOptions?: string
  width?: number
  height?: number
  callback(): void
}

export const chrome_extension_installed_cookie = 'chrome_extension_installed'

export function openWindow(options: OpenWindowOptions) {
  options.width = options.width || 800
  options.height = options.height || 800
  options.windowOptions = `location=0,status=0,width=${options.width},height=${options.height}`

  const openedWindow = window.open(
    options.path,
    options.windowName,
    options.windowOptions,
  )

  if (openedWindow) {
    const windowInterval = window.setInterval(() => {
      if (openedWindow && openedWindow.closed) {
        window.clearInterval(windowInterval)
        options.callback()
      }
    }, 1000)
  } else {
    window.open(options.path, options.windowName)
  }
}

export function setCookie(
  name: string,
  value: string,
  expires?: string,
  domain?: string,
) {
  if (typeof window === 'undefined') return
  const defaultDate = new Date()
  defaultDate.setMonth(defaultDate.getMonth() + 12)

  const domainSection = domain
    ? `domain=${domain}`
    : `domain=${window?.location?.hostname}`

  const cookieStr = [
    `${name}=${value}`,
    domainSection,
    'path=/',
    `expires=${expires || defaultDate}`,
    'Secure',
  ].join(';')

  document.cookie = cookieStr
}

export function getCookie(cookieName: string) {
  const name = cookieName + '='
  if (typeof document !== 'undefined') {
    const decodedCookie = decodeURIComponent(document.cookie)
    const ca = decodedCookie.split(';')

    for (let i = 0; i < ca.length; i++) {
      const c = ca[i].trimLeft()

      if (c.indexOf(name) == 0) return c.substring(name.length, c.length)
    }
  }

  return null
}

export function deleteCookie(cookieName: string) {
  setCookie(cookieName, '', 'Thu, 01 Jan 1970 00:00:00 UTC')
  setCookie(
    cookieName,
    '',
    'Thu, 01 Jan 1970 00:00:00 UTC',
    '.crystalknows.com',
  )
}

export function getQueryParam(
  param: string | string[] | undefined,
): string | undefined {
  return param !== undefined && param !== null ? String(param) : undefined
}

export function getQueryParamArray(
  param: string | string[] | undefined,
): string[] {
  if (param === undefined) return []
  else if (Array.isArray(param)) return param
  else return [param]
}

export const isLinkedin = (URL?: string | null) => {
  const url = URL || window.location.href

  return !!url.match(/linkedin.com/)
}
